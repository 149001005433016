// 文本超出个数隐藏指令
// <div style="width: 400px;" v-ellipsisText></div>

import { resIsNumber } from "../whatReasult";

// <div style="width: 400px;" v-ellipsisText="3"></div>
const ellipsisText = {
    mounted (el: any, binding: any) {
      const { value } = binding
      if((el.innerHTML.length>value)&&resIsNumber(value)){
        el.title = el.innerHTML;
        el.innerHTML= el.innerHTML.slice(0,value).concat('...');
      }

    }
}

export default ellipsisText