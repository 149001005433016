export default {
    // 注册来源
    SOURCE_OPTIONS:[
        {label:'系统添加',value:1},
        {label:'用户注册',value:2},
        {label:'批量导入',value:3},
        {label:'微信小程序',value:4}
    ],
    SOURCE_REFRENCE:{
        1:'系统添加',
        2:'用户注册',
        3:'批量导入',
        4:'微信小程序',
    },
    // 角色下拉框
    ROLE_OPTION: [
        { label:'医生',value:1},
        { label:'护士',value:2},
        { label:'护士长',value:3},
        { label:'科室主任',value:4},
    ],
    // 性别
    GENDER:{
        1:'男',
        0:'女',
    },
    // 性别下拉框
    GENDER_OPTION:[
        { label:'男',value:1},
        { label:'女',value:0},
    ],
    // 民族数组
     NATION_ARRAY:[
        "汉族",
        "蒙古族",
        "回族",
        "藏族",
        "维吾尔族",
        "苗族",
        "彝族",
        "壮族",
        "布依族",
        "朝鲜族",
        "满族",
        "侗族",
        "瑶族",
        "白族",
        "土家族",
        "哈尼族",
        "哈萨克族",
        "傣族",
        "黎族",
        "僳僳族",
        "佤族",
        "畲族",
        "高山族",
        "拉祜族",
        "水族",
        "东乡族",
        "纳西族",
        "景颇族",
        "柯尔克孜族",
        "土族",
        "达斡尔族",
        "仫佬族",
        "羌族",
        "布朗族",
        "撒拉族",
        "毛南族",
        "仡佬族",
        "锡伯族",
        "阿昌族",
        "普米族",
        "塔吉克族",
        "怒族",
        "乌孜别克族",
        "俄罗斯族",
        "鄂温克族",
        "德昂族",
        "保安族",
        "裕固族",
        "京族",
        "塔塔尔族",
        "独龙族",
        "鄂伦春族",
        "赫哲族",
        "门巴族",
        "珞巴族",
        "基诺族"
    ],
    // 民族数组映射
     NATION_OBJECT: {
        "汉族": 1,
        "蒙古族": 2,
        "回族": 3,
        "藏族": 4,
        "维吾尔族": 5,
        "苗族": 6,
        "彝族": 7,
        "壮族": 8,
        "布依族": 9,
        "朝鲜族": 10,
        "满族": 11,
        "侗族": 12,
        "瑶族": 13,
        "白族": 14,
        "土家族": 15,
        "哈尼族": 16,
        "哈萨克族": 17,
        "傣族": 18,
        "黎族": 19,
        "僳僳族": 20,
        "佤族": 21,
        "畲族": 22,
        "高山族": 23,
        "拉祜族": 24,
        "水族": 25,
        "东乡族": 26,
        "纳西族": 27,
        "景颇族": 28,
        "柯尔克孜族": 29,
        "土族": 30,
        "达斡尔族": 31,
        "仫佬族": 32,
        "羌族": 33,
        "布朗族": 34,
        "撒拉族": 35,
        "毛南族": 36,
        "仡佬族": 37,
        "锡伯族": 38,
        "阿昌族": 39,
        "普米族": 40,
        "塔吉克族": 41,
        "怒族": 42,
        "乌孜别克族": 43,
        "俄罗斯族": 44,
        "鄂温克族": 45,
        "德昂族": 46,
        "保安族": 47,
        "裕固族": 48,
        "京族": 49,
        "塔塔尔族": 50,
        "独龙族": 51,
        "鄂伦春族": 52,
        "赫哲族": 53,
        "门巴族": 54,
        "珞巴族": 55,
        "基诺族": 56
    },
    // 学历数组
     EDUCATIONAL_ARRAY : ["小学", "初中", "高中","职高", "大专", "本科","硕士"],
     //学历映射
     EDUCATION_OBJECT : {
        "小学": 1,
        "初中": 2,
        "高中": 3,
        "职高": 4,
        "大专": 5,
        "本科": 6,
        "硕士": 7
    },
    // 婚姻数组
     MARITAL_ARRAY : ["已婚", "未婚", "离异", "丧偶"],
     MARITAL_OBJECT : {
        "已婚": 1,
        "未婚": 2,
        "离异": 3,
        "丧偶": 4
    },
    // 职业数组
     PROFESSION_ARRAY : ["文员", "工人", "教师", "农民", "其他"],
     PROFESSION_OBJECT:{
        "文员": 1,
        "工人": 2,
        "教师": 3,
        "农民": 4,
        "其他": 5
    },
    // 工作状态
     WORKSTATUS_ARRAY : ["无业", "在职", "离职", "退休"],
     WORKSTATUS_OBJECT : {
        "无业": 1,
        "在职": 2,
        "离职": 3,
        "退休": 4
    },
    // 居住地区
     RESIDENCE_ARRAY : ["农村", "城镇"],
     RESIDETYPE_OBJECT : {
        "农村": 1,
        "城镇": 2
    },
    // 居住情况
     RESIDENSTATE_ARRAY :["独居", "和配偶独住", "和子女同住", "其他"],
     RESIDESTATUS_OBJECT : {
        "独居": 1,
        "和配偶独住": 2,
        "和子女同住": 3,
        "其他": 4
    },
    // 吸烟
    SMOKE_ARRAY:['不吸烟','偶尔吸','经常吸','高频吸'],
    SMOKE_OBJECT:{
        '不吸烟':1,
        '偶尔吸':2,
        '经常吸':3,
        '高频吸':4,
    },
    // 饮酒
    DRINK_ARRAY:['不饮酒','偶尔饮','经常饮','高频饮'],
    DRINK_OBJECT:{
        '不饮酒':1,
        '偶尔饮':2,
        '经常饮':2,
        '高频饮':2,
    },
    // 疾病史

    // 病程
    DISEASE_ARRAY:['一年以内','1~3年','3~5年','5年以上'],
    DISEASE_OBJECT:{
        '一年以内':1,
        '1~3年':2,
        '3~5年':3,
        '5年以上':4,
    },
    // 入院治疗
    HOSPITALIZED_ARRAY:['否','1~3个月','3~6个月','6~12个月','一年以上'],
    HOSPITALIZED_OBJECT:{
        '否':1,
        '1~3个月':2,
        '3~6个月':3,
        '6~12个月':4,
        '一年以上':5,
    },
    // 服药史
    // 服药时长
    DURATION_ARRAY:['一年以内','1~3年','3~5年','5年以上'],
    DURATION_OBJECT:{
        '一年以内':1,
        '1~3年':2,
        '3~5年':3,
        '5年以上':4,
    },
}