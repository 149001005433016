//      引入创建虚拟节点和渲染方法
import { createVNode, render } from "vue";
import tip from "@/components/UI/MyMessage.vue";

class Message {

    private showMessage(str: string, type: string) {
        const div = document.createElement("div");
        document.body.appendChild(div);
        const vnode = createVNode(tip, { str, type });
        render(vnode, div);
        const out = setTimeout(() => {
            render(null, div);
            clearTimeout(out);
        }, 2000);
        return this;
    }

    public static success(str: string) {
        new Message().showMessage(str, 'success');
    }

    public static error(str: string) {
        const o = new Message();
        o.showMessage(str, 'error');
    }

    public static warning(str: string) {
        new Message().showMessage(str, 'warn');
    }

    public static info(str: string) {
        new Message().showMessage(str, 'info');
    }
}

export default Message;

