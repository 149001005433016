// 文本超出隐藏指令
// <div style="width: 400px;" v-ellipsis></div>
// <div style="width: 400px;" v-ellipsis="3"></div>
const ellipsis = {
    mounted (el: any, binding: any) {
      
      const { value } = binding
      if (!value || Number(value) === 1) {
        el.style.display = 'block'
        el.style.overflow = 'hidden'
        el.style['white-space'] = 'nowrap'
        el.style['text-overflow'] = 'ellipsis'
        if (el.scrollWidth > el.clientWidth) {
          el.title = el.innerHTML
        }
      } else if (!isNaN(value)) {
        el.style.display = '-webkit-box'
        el.style.overflow = 'hidden'
        el.style['-webkit-box-orient'] = 'vertical'
        el.style['-webkit-line-clamp'] = value
        if (el.scrollHeight > el.clientHeight) {
          el.title = el.innerHTML
        }
      }
    }
}

export default ellipsis
