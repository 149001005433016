// 图片出错时替换指令
//  <img v-RealImg="'images/logo.png'" src="images/errorLogo.png" />
const realImg = {
  async beforeMount(el:any, binding:any) {
    const imgURL = binding.value;
    if (imgURL) {
      const exist = await imageIsExist(imgURL);
      exist && el.setAttribute('src', imgURL);
    }
    // 判断一个图片是否存在, 注意是异步行为
    function imageIsExist(url:any) {
      return new Promise(resolve => {
        let img = new Image();
        img.src = url;
        img.onload = () => {
          if (img.complete) {
            resolve(true);
            img = null;
          }
        }
        img.onerror = () => {
          resolve(false);
          img = null;
        }
      })
    }
  }
}

export default realImg
