import iconWork from './iconWork.vue';
import iconSetting from './iconSetting.vue';
import iconPatient from './iconPatient.vue';
import iconOragniza from './iconOrganization.vue';
import iconBlueprint from './iconBlueprint.vue';
import iconArticle from './iconArticle.vue';
export default {
    iconWork,
    iconSetting,
    iconPatient,
    iconOragniza,
    iconBlueprint,
    iconArticle
}