import http from '@/store/http';

// 获取登陆验证码
export const getVerifyCode = (data) => http.request('api/sendLoginVerifyCode', 'post', data);

// 用户验证码登陆
export const loginByVerifyCode = (data) => http.request('/api/loginByVerifyCode', 'post', data);
// 用户密码登录
export const loginByPassword = (data) => http.request('/api/login', 'POST', data);
// 退出操作
export const loginOut = () => http.request('/api/exit', 'post', {});

// 用户注册
export const registerAccount = (data) => http.request('/api/regByVerifyCode', 'post', data);
// 用户基本信息查询
export const getUserInfo = () => http.request('/api/user/info', 'POST');
// 用户信息更改
export const editUserInfo = (data) => http.request('/api/user/editInfo', 'POST', data);
// 用户头像更改
export const editUserHeadImage = (data) => http.request('/api/user/editHeadImg', 'POST', data);
// 获取登录的二维码
export const loginQrCode = () => http.request('/api/getLoginWeixinQrcode', 'POST');
// 获取是否登录
export const queryIsLogin = (data) => http.request('/api/weixinLogin', 'POST', data);
