
//量表子节点类型
// const BLUE_SCALE_NODETYPE = [
// 	// { label:'基本信息节点',value:'default'},
// 	{ label:'因子节点',value:'measure_factor'},
// 	{ label:'问题节点',value:'measure_question'},
// ];
const BLUE_SCALE_NODETYPE = {
	measure_main:[
		{ label:'普通节点',value:'default'},
		{ label:'因子节点',value:'measure_factor'},
		{ label:'问题节点',value:'measure_question'},
		{ label:'动作节点',value:'node_action'},
	],
	default:[
		{ label:'普通节点',value:'default'},
		{ label:'因子节点',value:'measure_factor'},
		{ label:'问题节点',value:'measure_question'},
		{ label:'动作节点',value:'node_action'},
	],
	measure_factor:[
		{ label:'普通节点',value:'default'},
		{ label:'因子结论',value:'measure_conclusion'},
		{ label:'动作节点',value:'node_action'},
	],
	measure_question:[
		{ label:'普通节点',value:'default'},
		{ label:'问题答案',value:'measure_answer'},
		{ label:'动作节点',value:'node_action'},
	],
	node_action:[
		{ label:'普通节点',value:'default'},
		{ label:'因子节点',value:'measure_factor'},
		{ label:'问题节点',value:'measure_question'},
		{ label:'动作节点',value:'node_action'},
	],
	measure_conclusion:[
		{ label:'普通节点',value:'default'},
		{ label:'动作节点',value:'node_action'},
	],
	measure_answer:[
		{ label:'普通节点',value:'default'},
		{ label:'动作节点',value:'node_action'},
	]
};

const BLUE_ANSWER_TYPE = [
	{ label:'图片',value:'img'},{ label:'文本',value:'text'}
	// ,{ label:'视频',value:'video'},
];

const BLUE_QUEST_TYPE = [
	{ label:'单选',value:'check'},
	{ label:'复选',value:'radio'},
];

//因子类型
const FACTORTYPE_NODE = [
	{ label:'因子',value:'tag'},
	{ label:'总分',value:'base'},
];

const BLUE_QUEST_RULR = [
	{ label:'text',value:'text'},
	{ label:'number',value:'number'},
	{ label:'integer',value:'integer'},
	{ label:'radio',value:'radio'},
	{ label:'select',value:'select'},
	{ label:'date',value:'date'},
	{ label:'time',value:'time'},
	{ label:'date_time',value:'date_time'},
	{ label:'checkbox',value:'checkbox'},
];

// 因子提示
// const TIPS = [
// 	{ label:'-',value:'-'},
// 	{ label:'+',value:'+'},
// 	{ label:'↑',value:'↑'},
// 	{ label:'↓',value:'↓'},
// ];
const TIPS =['-','+','↑','↓'];

export default {
	TIPS,
	BLUE_QUEST_RULR,
	FACTORTYPE_NODE,
	BLUE_QUEST_TYPE,
	BLUE_SCALE_NODETYPE,
	BLUE_ANSWER_TYPE
}