import { createRouter, createWebHashHistory } from 'vue-router';
import { reactive } from 'vue';
import { RouteRecordRaw } from 'vue-router';

// import routes from './routes';
// import guards from './guards';
const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    // redirect: '/login',
    meta: {
      icon: 'LoginOutlined',
      view: 'blank',
      target: '_blank',
      cacheable: false,
      renderMenu: false,
    },
    component: () => import('@/pages/login/Login.vue'),
  },
  {
    path: '/',
    name: 'index',
    redirect: '/index',
    component: () => import('@/pages/index/index.vue'),
    children: [
      {
        path: '/index',
        meta: {
          icon: 'LoginOutlined',
          view: 'blank',
          target: '_blank',
          cacheable: false,
          renderMenu: false,
        },
        component: () => import('@/pages/index/components/index.vue'),
      },
      {
        path: '/articleDetile',
        component: () => import('@/pages/articleDetile/articleDetile.vue'),
      },
      {
        path: '/searchDetile',
        component: () => import('@/pages/searchDetile/searchDetile.vue'),
      },
      {
        path: '/classification',
        component: () => import('@/pages/classification/index.vue'),
      },
      {
        path: '/classifyNext',
        component: () => import('@/pages/classification/classifyNext.vue'),
      },
      {
        path: '/classifyLast',
        component: () => import('@/pages/classification/classifyLast.vue'),
      },
      {
        path: '/server',
        component: () => import('@/pages/servers/index.vue'),
      },
      {
        path: '/tools',
        component: () => import('@/pages/servers/tools.vue'),
      },
      {
        path: '/questionnaire',
        component: () => import('@/pages/servers/questionnaire/index.vue'),
      },
      {
        path: '/evaluation',
        component: () => import('@/pages/servers/questionnaire/evaluation.vue'),
      },
      {
        path: '/result',
        component: () => import('@/pages/servers/questionnaire/result.vue'),
      },
      {
        path: '/community',
        component: () => import('@/pages/community/index.vue'),
      },
      {
        path: '/groupList',
        component: () => import('@/pages/community/groupList.vue'),
      },
      {
        path: '/groupDetile',
        component: () => import('@/pages/community/groupDetile.vue'),
      },
      {
        path: '/discusDetile',
        component: () => import('@/pages/community/discusDetile.vue'),
      },
      {
        path: '/selfInfo',
        component: () => import('@/pages/selfInfo/index.vue'),
      },
      {
        path: '/policy',
        component: () => import('@/pages/privacyPolicy.vue'),
      },
      {
        path: '/addDiscus',
        component: () => import('@/pages/community/addDiscus.vue'),
      },
      {
        path: '/mobileLogin',
        component: () => import('@/pages/servers/components/noLogin.vue'),
      },
      {
        path: '/editUserInfo',
        component: () => import('@/pages/selfInfo/components/editUserInfo.vue'),
      },
    ],
  },
  {
    path: '/onSetting',
    component: () => import('@/pages/onSetting.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
];
const router = createRouter(
  reactive({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
      return { top: 0 };
    },
  })
);

// 注册导航守卫
// guards.before.forEach(router.beforeEach);
// guards.after.forEach(router.afterEach);

export default router;
