export interface LoginForm {
    username: string;
    password: string;
}

export interface Response<T = never> {
    msg: string;
    code: number;
    data: T;
}

export function isResponse(obj: any): obj is Response<any> {
    return typeof obj === 'object' && obj.msg !== undefined && obj.code !== -1 && obj.code !== 403;
}
