const store = window.localStorage;

class LocalStore {
  public static set(key: string, value: unknown): void {
    if (!store) return;
    const v = value;
    try {
      if (typeof v === "string") {
        store.setItem(key, v);
      } else {
        store.setItem(key, JSON.stringify(v));
      }
	  const setEvent = new Event('setItemEvent');
	  window.dispatchEvent(setEvent);
    } catch (e) {
      console.log(e);
    }
  }

  public static get(key: string): any {
    if (!store) return;
    const data = store.getItem(key);
    try {
      if (data) {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  public static remove(key: string): void {
    if (!store) return;
    try {
      store.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }
}

export default LocalStore;
