// 防抖
// fn:方法名称 args:传递的值
// <button v-debounce="{fn:test,args:'测试'}">测试</button>
const debounce = {
  mounted(el:any, binding:any) {
    let timer:any
    el.addEventListener('click', () => {
      if (timer) {
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        binding.value.fn(binding.value.args)
      }, binding.value.dep)
    })
  }
}

export default debounce
