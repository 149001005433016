// 元素放大缩小
// 


// <button v-debounce="{fn:test,args:'测试'}">测试</button>
const elScale = {
    mounted(el:any) {
        let dom = document.getElementsByClassName('stepin-tabs-view-content')[0]
        let scal = 1;
        const fn = (e)=>{
            if (e.preventDefault) {/*FF 和 Chrome*/
            e.preventDefault();// 阻止默认事件
            }
        };
        el.addEventListener('mouseenter',()=>{
            dom.addEventListener('wheel',fn);
            el.addEventListener('wheel',(e)=>{
                console.log(el.style);
                if(e.wheelDelta>0){
                    scal = (parseFloat(scal as any) + 0.05).toFixed(2) as any;
                    el.style.transform = "scale(" + scal + ")";    //scale()在这里要使用拼接的方式才能生效
                    el.style.transformOrigin = '0 0';
                }else{
                    if (scal == 0.05) {
                        scal = 0.05
                        return
                    } else {
                        scal = (parseFloat(scal as any) - 0.05).toFixed(2) as any;
                    }
                    el.style.transform = "scale(" + scal + ")";    //scale()在这里要使用拼接的方式才能生效
                    el.style.transformOrigin = '0 0';
                }

                return false
            })
        });
        el.addEventListener('mouseleave',()=>{
            console.log(2);
            dom.removeEventListener('wheel',fn)
            
        });

    }
  }
  
  export default elScale