// 按钮权限
// import cache from "@/utils/cache";

export default {
    mounted(el: any) {
        // 超级管理拥有所有权限 
        // const USER_INFO = cache.getCache("userInfo");
        // if(USER_INFO.id == 1) return;
        const url = el.getAttribute("url");
        const datatype = el.getAttribute("data-type");
        // 获取权限列表
        // const USER_API = cache.getCache("userApi");
        const USER_API = [{ name: "222" }]
        if (datatype == 0) {
            const ones = USER_API.filter((item: any) => item.name.includes(url));
            if (ones.length > 0) return;
            el.parentNode.parentNode.remove();
        } else {
            const ones = USER_API.filter((item: any) => item.name.includes(url));
            if (ones.length > 0) return;
            el.parentNode.removeChild(el);
        }
    },
};
