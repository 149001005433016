import blueForm from "./blue";
import consts from './consts';
type options = {value: string | number, label: string | number};

const BLUE_MAIN_NODETYPE:options[] = [
	{ label:'量表',value:'measure'},
	{ label:'采集信息',value:'gather'},
];

const STATUS = {
	0:'关闭',
	1:'开启'
};

export default {
	BLUE_MAIN_NODETYPE,
	STATUS,
	...blueForm,
	...consts
}