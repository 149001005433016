import { AxiosRequestConfig, AxiosResponse } from 'axios';
import createHttp from '@/utils/axiosHttp';
import { isResponse } from '@/types';
import NProgress from 'nprogress';
import Message from '@/utils/message';
import { useRouter } from 'vue-router';
import LocalStore from '@/utils/LocalStore';
// const token = localStorage.getItem('token');
const token = LocalStore.get('token');
// const http = createHttp({
//   timeout: 10000,
//   baseURL: 'https://api.leguhealth.com',
//   withCredentials: true,
//   xsrfCookieName: 'Authorization',
//   xsrfHeaderName: 'Authorization',
//   headers: {
//     Authorization: token == null ? '' : `Bearer ${token}`,
//   },
// });
const http = createHttp({
    baseURL: 'https://api.leguhealth.com',
    // baseURL: '/admin',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token == null ? '' : token,
    },
    // 其他Axios配置项
    // transformRequest: [(data) => qs.stringify(data)],
});
const isAxiosResponse = (obj: any): obj is AxiosResponse => {
    // console.log(obj,"pp");
    return typeof obj === 'object' && obj.status && obj.statusText && obj.headers && obj.config;
};

// progress 进度条 -- 开启
http.interceptors.request.use((req: AxiosRequestConfig) => {
    if (localStorage.getItem('token')) {
        req.headers.token = localStorage.getItem('token');
    }
    if (!NProgress.isStarted()) {
        NProgress.start();
    }

    return req;
});
// 错误拦截
function handleU(result: any) {
    const { code, data, msg } = result;
    if (code == -1) {
        return result;
    }
    const obj = {
        0: () => {
            // Message.success(prompt);
            return Promise.reject(data);
        },
        422: () => {
            Message.error(msg);
            return undefined;
        },
        403: () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= 768) {
                localStorage.removeItem('token');
                window.location.replace('#/mobileLogin');
                return;
            }
            Message.error('该功能需要登录后使用！');
            const backRoute = 1;
            setTimeout(() => {
                localStorage.removeItem('token');
                window.location.replace(`#/login?${backRoute}`);
            }, 0);
        },
        10: () => {
            Message.error(msg);
        },
        1: () => {
            Message.error(msg);
        }
    };
    if (obj[code]) {
        return obj[code]();
    }
    return undefined;
    // console.log(data);
    // return Promise.reject(data.data);
}

// 解析响应结果
// interface AxiosResponseType = {
//     msg: string;
//     data: [] | { list: [] };
//     code: number;
// }
http.interceptors.response.use(
    (rep: AxiosResponse<String>) => {
        const { status, data, config } = rep;
        // token续费
        // localStorage.setItem('token', config.headers.Authorization ? config.headers.Authorization.split(' ')[1] : null);
        if (status == 200 || status == 202 || status == 201) {
            if (isResponse(data)) {
                return data.code === 0 ? data : Promise.reject(data);
            }
            return handleU(data);
            // return Promise.reject(data.data);
        } else {
            return undefined;
        }

        // const { data } = rep;
        // console.log(data, "解析响应结果",isResponse(data));
        // if (isResponse(data)) {
        // 	return data.code === 0 ? data : Promise.reject(data);
        // }
        // if(rep.status == 200){
        // 	return Promise.reject(data);
        // }
        // return Promise.reject({ message: rep.statusText, code: rep.status, data });
    },
    (error) => {
        if (error.response && isAxiosResponse(error.response)) {
            // console.log(error.response.status, "解析响应结果error");
            return handleU({
                code: error.response.status,
                prompt: error.response.data.prompt,
                data: error.response.data,
            });

            // return Promise.reject({
            // 	message: error.response.statusText,
            // 	code: error.response.status,
            // 	data: error.response.data,
            // });
        }
        return handleU({
            code: 422,
            prompt: '系统错误',
            data: [],
        });
        // return undefined;
        // return Promise.reject(error);
    }
);

// progress 进度条 -- 关闭
http.interceptors.response.use(
    (rep) => {
        if (NProgress.isStarted()) {
            NProgress.done();
        }
        return rep;
    },
    (error) => {
        if (NProgress.isStarted()) {
            NProgress.done();
        }
        return error;
    }
);

export default http;
