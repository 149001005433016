import { defineStore } from 'pinia';
import { getUserInfo } from '@/network/login';

export const useLogin = defineStore('login', {
  state: () => {
    return {
      userInfo: {
        headImgUrl: '',
        nickName: '',
        weixinBindStatus: '',
        pwdBindStatus: '',
        mobileBindStatus: '',
        createTime: '',
      },
      mobilePhone: '',
    };
  },
  actions: {
    // 获取用户信息
    async queryUserInfo() {
      const res = await getUserInfo();
      this.userInfo = res.data;
    },
  },
  persist: true, //持久化存储开关
});
