// 加密指令
// 支持三种修饰符 name：两个字隐藏后一个，两个字以上隐藏中间（默认）； 
// mobile：手机号码，展示前 3 后 4； 
// idcard：身份证，展示前 6 后 4 
// 使用: 在 Dom 上加上 v-hide-text.name
const hideText = {
    beforeMount(el: any, {
        modifiers
    }) {

        //设置默认值为name
        Object.keys(modifiers).length === 0 && (modifiers.name = true);
        if (modifiers.mobile) {
            // 手机号码
            el.innerText = el.innerText.replace(/^(\d{3})\d+(\d{4})$/, '$1****$2');
        } else if (modifiers.name) {
            // 姓名 两个字隐藏后一个，两个字以上隐藏中间
            el.innerText = el.innerText.length > 2 ? el.innerText.replace(/^(\S{1})\S+(\S{1})/, '$1*$2') : el.innerText.substring(0, 1) + '*';
        } else if (modifiers.idcard) {
            //身份证
            el.innerText = el.innerText.replace(/\d{4}(?=.{4})/g, '****');
        }
    }
};
export default hideText
