// 自定义指令
import hasBtnPermission from "./hasBtnPermission";
import debounce from "./debounce";
import input from "./input";
import lazyLoad from "./lazyLoad";
import draggable from "./draggable";
import ellipsis from "./ellipsis";
import ellipsisText from "./ellipsisText";
import hideText from './hideText';
import formatText from './formatText';
import RealImg from './RealImg';
import longpress from './longpress';
import elScale from './elementScale';

const obj: any = {
  hasBtnPermission,
  debounce,
  input,
  lazyLoad,
  draggable,
  ellipsis,
  hideText,
  formatText,
  RealImg,
  longpress,
  ellipsisText,
  elScale,
};

export default {
  install(Vue: any) {
    Object.keys(obj).forEach((key) => {
      Vue.directive(key, obj[key]);
    });
  },
};
