// 字串分割指令
// 支持传入指定的绑定值，
// 参数和修饰符 value 分隔符，默认为, 
// arg 指令参数，默认为 3，每隔 3 个字符分割一次 
// modify 暂时只支持传入 money(金额千分位)
/*<div v-format-text.money="','">123456789</div>
  <!-- 123,456,789 -->
  <div v-format-text.money="'_'">123456789</div>
  <!-- 123_456_789 -->
  <div v-format-text.money:4="','">123456789</div>
  <!-- 1234,4567,89 -->
  <div v-format-text.money:4="','">123456789</div>
  <!-- 1234,4567,89 -->
  <div v-format-text>abcdefghi</div>
  <!-- abc,def,ghi- -->
*/
const formatText = {
    beforeMount(el: any, binding: any) {
        let { value = ',', arg = 3, modifiers } = binding;
        if (modifiers.money) {
            // 金额
            el.innerText = el.innerText.replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, value));
        } else if (modifiers.coin) {
            if (typeof (el.innerText * 1) === 'number') {
                el.innerText = el.innerText / 100
            } else {
                el.innerText = el.innerText
            }
        } else {
            // 普通字符串
            let regText = '\\B(?=(\\w{' + arg + '})+(?!\\w))';
            let reg = new RegExp(regText, 'g');
            el.innerText = el.innerText.replace(reg, value);
        }
    }
};
export default formatText
