import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
// import stepin from 'stepin/es';
import pinia from '@/store';
// import piniaPersist from 'pinia-plugin-persistedstate';
import '@/assets/style/global.less';
// import '@/mock';
// 生产打包时可去除 ant-design-vue/dist/antd.variable.less 的引用。
// 开发引入此包是为了加载优化，防止首次打开页面过慢
import 'ant-design-vue/dist/antd.variable.less';
import 'stepin/es/style';
// import vue3TreeOrg from 'vue3-tree-org';
// import "vue3-tree-org/lib/vue3-tree-org.css";
// import 'default-passive-events';
// import '@/theme/index.less';
// import '@/common.less';
// import { AuthPlugin, IconfontPlugin } from '@/plugins';
import options from '@/common/options';
import { createPinia } from 'pinia';
// import { ConfigProvider } from "ant-design-vue";
import directive from '@/utils/directive/index';
// import svgIcon from '@/components/iconImg/meauIcon.vue';
// import icomCom from "@/components/iconImg/iconNav.vue";
// import iconNav from '@/mock/icon_navigation_workbench_nor.svg';
import meauIcon from '@/components/iconImg/meauIcon';
const app = createApp(App);
// const piniaAll = createPinia()
app.use(pinia);
// app.use(ConfigProvider);
// piniaAll.use(piniaPersist);
app.use(router);
// app.use(vue3TreeOrg);
app.use(directive);
// app.use(stepin, { router });
// app.use(AuthPlugin, { action: 'disable' });
// iconfont 插件。url为你的 iconfont 图标资源地址（你的iconfont 仓库可获取此地址）
// app.use(IconfontPlugin, { url: '//at.alicdn.com/t/c/font_3805284_ulvha6ct7d.js' });
Object.keys(meauIcon).forEach((item) => {
  app.component(item, meauIcon[item]);
});
// app.component('iconNav',iconNav)
// //侧边图标
// app.component('svgIcon',svgIcon);

app.config.errorHandler = function (err) {
  console.error('异常，', err);
};
app.config.globalProperties.$OPTIONS = options;
app.mount('#stepin-app');
